html, body {

}
html, body,
.off-canvas-wrapper,
.off-canvas-content{
  width:100%;
  height: 100%;
  min-height:100%;
  -webkit-overflow-scrolling: touch;
}


.inner-content {
  position:relative;
  width:100%;
  height: 100%;
}
.icon {
  width:30px;
  height:auto;
}
.outer {

  // outer-shadow
  background-color:#fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  margin:2em auto;
  padding:1em;

  @include breakpoint(medium) {
    margin:0;
    padding:2em;
  }
  @include breakpoint(small) {
    margin:0;
    padding:2em;
  }
  @include breakpoint(large) {
    margin:2em auto;
    padding:2em;
  }

  // header
  h3, h4, h5 {

    color:$secondary-color;
    font-weight:bold;

  }

}
