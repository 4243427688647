.loginBox {

  z-index:9;
  @include breakpoint(medium) {
    position:relative;
    width:100%;
    height:auto;

  }
  @include breakpoint(small) {
    position:relative;

    width:100%;
    height:auto;
  }
  @include breakpoint(large) {

    //position:absolute;

    width:640px;
    //height:auto;
    //
    //left:50%;
    //top:50%;
    //
    //transform:translate(-50%,-50%);

  }

  &-holder {

    input {
      border:0;
      border-bottom:1px solid #aaa;
      box-shadow: none;

      &.valid {
        border-bottom:1px solid #3adb76;
      }

      &.error {
        border-bottom:1px solid #cc4b37;
      }
    }

    label {
      &.valid {
       color: #3adb76;
      }

      &.error {
        color: #cc4b37;
      }
    }

    textarea:focus, input:focus{
      outline: none;
      border:0;
    }

  }

}