body {

  display:block;
  width: 100%;
  height: 100%;

  background:url(../../images/background@2x.png);
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: center 0;
}