header {
  text-align:center;
  font-size:rem-calc(12);
}


.header {

  padding:2em;

  &-inner {

    margin:0 auto;
    display:table;

    .logo {
      width:63px;
      height:63px;
      margin-right:1em;
    }

    h3, h4, h5  {
      display:table-cell;
      vertical-align:middle;
    }

    h5 {
      text-transform: uppercase;
      color:purple;
      font-weight:800;
      font-family: "Nunito", Calibri, Helvetica, Arial, sans-serif;
    }
  }

}