
.menu-btn-holder {

}
.menu-btn {

  position:absolute;
  z-index:1;
  top:20px;
  left:20px;

  img {
    display:block;
    width:30px;
    height:auto;
  }

  span {
    color:#999;
  }

  span, img {
    display:inline-block;
  }

}

.nav {
  display:inline-block;
  box-shadow:inset -13px 0 20px -13px rgba(10, 10, 10, 0.25);
  .header {
    padding:2em;
    border-bottom:1px solid #ccc;
  }

  background-color:#fff;
  width:100%;
  height:100%;

  ul {
    margin:0;
    padding:0;
    li {
      display:block;
      width:100%;
      min-height:80px;
      border-bottom:1px solid #ccc;
      padding:1em;
      vertical-align: middle;

      &.current {
        background-color:#EF802C;
        a {
          color:#fff;
        }
      }

      a {
        vertical-align: middle;
        color:#000;
        display:block;
        width:100%;
        height:100%;
      }
    }
  }

  .footer {
    font-size:rem-calc(12px);
    margin:2em 0;
    padding:2em;

  }
  .logo {
    display:block;
    width:70px;
    height:auto;

  }

  .close-btn {
    outline:none;
  }

}
