.footer-content {

  text-align:center;
  font-size:rem-calc(12px);
  margin-top:40px;
  margin-bottom:20px;

  &.floater {
    position:absolute;

    width:840px;
    height:auto;

    left:50%;
    bottom:0;

    transform:translate(-50%,-50%);
  }

  hr {
    border:0;
    border-bottom:1px solid #ccc;
  }

  ul {

    list-style:none;

    margin:0;
    padding:0;

    li {


      @include breakpoint(small) {
        display:block;
      }

      @include breakpoint(medium) {
        display:inline;
      }

      @include breakpoint(large) {
        display:inline;
      }

      a {
        color:#000;
        &:hover {
          color:#F87B36;
        }
      }

      &:after {

        content:'|';
        display:inline;
        margin-left:5px;

        @include breakpoint(small) {
          content:'';

        }
        @include breakpoint(medium) {
          content:'|';
          display:inline;
          margin-left:5px;

        }
        @include breakpoint(large) {
          content:'|';
          display:inline;
          margin-left:5px;

        }
      }

      &:last-child:after {
        content:' '
      }


    }

  }

}