@import "../../../node_modules/foundation-sites/scss/foundation";
@import "settings";
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-everything;

@import 'components/menu';
@import 'components/backgrounds';
@import 'components/login';
@import 'components/header';
@import 'components/footer';
@import 'components/layout';